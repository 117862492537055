<template>
  <div>
    <div class="table-container">
      <table>
        <tr class="header-row">
          <th class="sticky-column" style="width: 80px" >No.</th>
          <th class="sticky-column-second" style="width: 100px" v-if="rowSelect">Select Row(s)</th>
          <th class="sticky-column-second" style="width: 100px" v-else>Open in New Tab</th>
          <th v-for="(header, h) in condTableHeaders" :key="h">
            {{ header.name }}
          </th>
        </tr>
        <template v-if="ordersExist">
          <tr
            v-for="(order, o) in orders"
            :key="o"
            :style="{ 'background-color': getRowColor(o, order.duplicate_status) }"
          >
            <td class="sticky-column">
            <!-- <td class="sticky-column" :style="{ 'background-color': getRowColor(o, order.duplicate_status) }"> -->
              <span>{{o+1}}</span>
            </td>
            <td class="sticky-column-second" :style="{ 'width': '100px', 'background-color': getRowColor(o, order.duplicate_status) }" v-if="rowSelect" >
              <b-button
                variant="outline-dark"
                size="sm"
                class="btn-select-row"
                @click="selectRow(o)"
                >Select</b-button
              >
            </td>
            <td class="sticky-column-second" :style="{ 'width': '100px', 'background-color': getRowColor(o, order.duplicate_status) }" v-else >
              <b-button
                variant="outline-dark"
                size="sm"
                class="btn-select-row"
                @click="openOrderInNewTab(order._id)"
                >Open</b-button
              >
            </td>
            <td v-for="(header, h) in condTableHeaders" :key="h">{{formatTableCell(header.key, order)}}</td>
          </tr>
        </template>
        <tr class="header-row foot-header-row" v-if="Object.keys(orders).length >= 25">
          <th class="sticky-column" style="width: 80px" >No.</th>
          <th class="sticky-column-second" style="width: 100px" v-if="rowSelect">Select Row(s)</th>
          <th class="sticky-column-second" style="width: 100px" v-else>Open in New Tab</th>
          <th v-for="(header, h) in condTableHeaders" :key="h">
            {{ header.name }}
          </th>
        </tr>
      </table>
    </div>
    <template v-if="isLoading">
      <div class="text-center d-flex align-self-center justify-content-center p-3">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </template>
    <template v-else-if="!ordersExist">
      <p class="d-flex align-self-center justify-content-center p-3">No Registrations Found</p>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  props: {
    // orders: {
    //   type: Object, // Specify the expected type of orders
    //   default: () => ({}), // Default to an empty object if not provided
    // },
    orders: {},
    selectedRows: {
      default: () => [],
    },
    rowSelect: {
      type: Boolean,
      default: false,
    },
    checkIfCurrentViewMatch:{
      type: Function,
    },
    VIEWS:{
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      tableHeaders: [
        {"name":"Cashnet TX ID","key":"cashnet_transaction_id"},
        {"name":"UOP Order Status","key":"order_status"},
        {"name":"Partner Order Date","key":"order_date"},
        {"name":"Course Status","key":"course_status"},
        {"name":"First Name","key":"first_name"},
        {"name":"Middle Name","key":"middle_name"},
        {"name":"Last Name","key":"last_name"},
        {"name":"Date of Birth","key":"date_of_birth"},
        {"name":"UOP Banner Registration","key":"banner_status"},
        {"name":"UOP Registration Date","key":"uop_banner_registration_date"},
        {"name":"Grade Status","key":"grade_status"},
        {"name":"UOP Banner Date","key":"uop_banner_grade_validation_date"},
        {"name":"Grade Submission Type","key":"grade_type"},
        {"name":"Course Completion Date","key":"course_completion_date"},
        {"name":"Course Grade","key":"course_grade"},
        {"name":"Grade Submission Date","key":"grade_submission_date"},
        {"name":"Course Number","key":"course_number"},
        {"name":"Course Title","key":"course_title"},
        {"name":"Course Credits","key":"course_credits"},
        {"name":"Course Credit Fee","key":"course_credit_fee"},
        {"name":"Course Fixed Credit Fee","key":"course_fixed_credit_fee"},
        {"name":"Course Total Credit Fee","key":"course_total_credit_fee"},
        {"name":"Course Start Date","key":"course_beginning_date"},
        {"name":"Course End Date","key":"course_ending_date"},
        {"name":"Email","key":"email"},
        {"name":"Phone","key":"phone_1"},
        {"name":"Phone 2","key":"phone_2"},
        {"name":"Student Previously Enrolled","key":"student_previously_enrolled"},
        {"name":"Student School District","key":"student_school_district"},
      ],
    };
  },
  methods: {
    selectRow(rowIndex) {
      this.$emit("selectRow", rowIndex);
    },
    openOrderInNewTab(objectId) {
      const url = window.location.href.split(/[?#]/)[0]+"?objectId="+objectId
      window.open(url, '_blank');
    },
    getRowColor(rowIndex, duplicate_status) {
      // if (duplicate_status && this.selectedRows.includes(rowIndex)) return "rgba(199, 68, 56,0.6)"
      // if (duplicate_status) return "rgba(199, 68, 56,0.2)"
      // if (this.selectedRows.includes(rowIndex)) return "rgb(60, 146, 220, 0.2)"
      if (duplicate_status && this.selectedRows.includes(rowIndex)) return "rgb(221, 143, 136)"
      if (duplicate_status) return "rgb(244, 218, 215)"
      if (this.selectedRows.includes(rowIndex)) return "rgb(216, 233, 248)"
      return "white";
    },
    formatTableCell(key, order) {
      //Format date to MM/DD/YY
      if (key === "order_date") return this.formatDate(order[key])
      if (key === "uop_processed_date" && order[key]) return this.formatDate(order[key])
      if (key === "deleted_date" && order[key]) return this.formatDate(order[key])
      if (key === "uop_banner_registration_date" && order[key]) return this.formatDate(order[key])
      if (key === "uop_banner_grade_validation_date" && order[key]) return this.formatDate(order[key])
      if (key === "course_completion_date" && order[key]) return this.formatDate(order[key])
      if (key === "grade_submission_date" && order[key]) return this.formatDate(order[key])
      if (key === "uop_process_failed_status" && order[key]) return order[key]
      if (key === "uop_process_failed_date" && order[key]) return this.formatDate(order[key])
      if (key === "refund_date" && order[key]) return this.formatDate(order[key])
      if (key === "ld_coursework_submitted_date" && order[key]) return this.formatDate(order[key])
      if (key === "ld_coursework_submitted_dates" && order[key] && order[key].length > 0) {
        var dates = []
        order[key].forEach(function(date) { dates.push(this.formatDate(date)) }.bind(this))
        return dates.join(', ')
      } else if(key === "ld_coursework_submitted_dates") { return "" }
      //Make status readable
      if (key === "order_status") return order[key] ? "Processed" : "Not-Processed";
      if (key === "course_status") return order[key] ? "Completed" : "In-Progress";
      if (key === "banner_status") return order[key] ? "Registered" : "Not-Registered";
      if (key === "grade_status") return order[key] ? "Graded" : "Not-Graded";
      if (key === "grade_type") return order[key]===null ? "" : (order[key] ? "UOP Banner" : "Inside Pacific")
      if (key === "wc_order_status") return order[key] ? 
         (order[key] == "completed" ? "Completed" : (order[key] == "tf-part-completed" ? 
         "Partially completed" : "Processing")) : "N/A";
         

        
      if (key === "wc_order_id") return order[key] ?? "N/A"
      //Course info
      if (key == "course_number") return order.course.course_number ?? ""
      if (key == "course_title") return order.course.course_title ?? ""
      //Customer info
      if (key == "first_name") return order.customer ? order.customer.first_name ?? "" : ""
      if (key == "last_name") return order.customer ? order.customer.last_name ?? "" : ""
      if (key == "date_of_birth") return order.customer ? this.formatDate(order.customer.date_of_birth) : ""
      //Credit Fee
      if (key === "course_credit_fee") return ("$"+order[key] ?? 0)
      if (key === "course_fixed_credit_fee") return ("$"+order[key] ?? 0)
      //Cashnet Info
      if (key == "cashnet_transaction_id") {
        // return (order.cashnet_transactions[0]) ? order.cashnet_transactions[0].id : "N/A"
        return (order.cashnet_transactions.length > 0) ? order.cashnet_transactions.map(e => e.id).join(', ') : "N/A"
        }
      if (key === "course_total_credit_fee") {
        const total_credit_fee = parseInt(((order["course_credits"] ?? 0)*(order["course_credit_fee"] ?? 0)));
        const fixed_credit_fee = parseInt(order["course_fixed_credit_fee"] ?? 0);
        return "$"+parseInt(total_credit_fee+fixed_credit_fee)
      }

      return order[key];
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' })
    },
    checkIfAllowed(payload = []) {
      const userRole =
        this.$store.state.user && this.$store.state.user.user_role;
      return payload.includes(userRole);
    },
    checkIfPartnerMatch(payload) {
      const userRole =
        this.$store.state.user && this.$store.state.user.user_role;
      const partners =
        this.$store.state.user.partners && this.$store.state.user.partners;
      let result = false
      if (
        partners &&
        partners.length &&
        payload.length &&
        userRole === "partner"
      ) {
        result = partners.some((each) => payload.includes(each));
      }

      return result;
    }
  },
  computed: {
    condTableHeaders () {
      //Set the params if this is the main order table

      /*
      *  All header keys visibility checks
      *    role == "admin"
      *     --> wc_order_status
      *     --> wc_order_id 
      *    admin = true && order_status = true
      *     --> cashnet_transaction_id 
      *    params && params.order_status == true
      *     --> uop_processed_date
      *    params && params.banner_status == true
      *     --> uop_banner_registration_date
      *    params &&  grade_status = true
      *     --> uop_banner_grade_validation_date
      *    params && course_status
      *     --> grade_type
      *     --> course_completion_date
      *     --> course_grade
      *     --> grade_submission_date
      * 
      */
      const params = (this.$parent.selectedViewObject) ? this.$parent.selectedViewObject.params : false
      const role = this.$store.state.user.user_role
      const columns = []

      columns.push({"name":"UC Order ID","key":"order"})
      columns.push({"name":"Partner ID","key":"partner_id"})
      columns.push({"name":"Partner Order Date","key":"order_date"})
        if(this.checkIfCurrentViewMatch && this.checkIfCurrentViewMatch([this.VIEWS.DELETED])){
          columns.push({"name":"Deleted Date","key":"deleted_date"})
        }
        if(this.checkIfCurrentViewMatch && this.checkIfCurrentViewMatch([this.VIEWS.FAILED])){
          columns.push({"name":"UOP Process Failed Date","key":"uop_process_failed_date"})
        }
        if(this.checkIfCurrentViewMatch && this.checkIfCurrentViewMatch([this.VIEWS.REFUNDED])){
          columns.push({"name":"Refund Date","key":"refund_date"})
        }

      // params.ld_assignments
      if (params && params.ld_assignments) columns.push( {"name":"LD Coursework Submitted","key":"ld_coursework_submitted_dates"})

      // role == "admin"
        // --> wc_order_status 
        // --> wc_order_id 
      if (role == "admin") columns.push( {"name":"TF Order Status","key":"wc_order_status"})
      if (role == "admin") columns.push( {"name":"TF Order Number","key":"wc_order_id"})
      
      columns.push({"name":"UOP Order Status","key":"order_status"})
      
      // admin = true && order_status = true
        // --> cashnet_transaction_id 
      if (this.checkIfAllowed(['admin', 'uop_admin']) || this.checkIfPartnerMatch(['CT', 'MT', 'C4T', 'TE'])  && 
         (!params || params.order_status !== false)) columns.push( {"name":"Cashnet TX ID","key":"cashnet_transaction_id"})
      
      // params && params.order_status == true
        // --> uop_processed_date
      if (!params || params.order_status !== false) columns.push({"name":"UOP Processed Date","key":"uop_processed_date"})
      
      columns.push({"name":"Course Status","key":"course_status"})
      columns.push({"name":"First Name","key":"first_name"})
      columns.push({"name":"Middle Name","key":"middle_name"})
      columns.push({"name":"Last Name","key":"last_name"})
      columns.push({"name":"Date of Birth","key":"date_of_birth"})
      columns.push({"name":"UOP Banner Registration","key":"banner_status"})
      
      // params && params.banner_status == true
        // --> uop_banner_registration_date
      if (!params || params.banner_status !== false) columns.push({"name":"UOP Registration Date","key":"uop_banner_registration_date"})
      
      columns.push({"name":"Grade Status","key":"grade_status"})
      // params &&  grade_status = true
        // --> uop_banner_grade_validation_date
      if (!params || params.grade_status !== false) columns.push({"name":"UOP Banner Date","key":"uop_banner_grade_validation_date"})
      
      // params && order_status
        // --> grade_type
        // --> course_completion_date
        // --> course_grade
        // --> grade_submission_date

      if (!params || params.course_status !== false) {
        columns.push({"name":"Grade Submission Type","key":"grade_type"})
        if(!params || params && params.complete_course_grade === false) {
          columns.push({"name":"Course Incomplete Date","key":"course_completion_date"})
        } else {
          columns.push({"name":"Course Completion Date","key":"course_completion_date"})
        }
        columns.push({"name":"Course Grade","key":"course_grade"})
        columns.push({"name":"Grade Submission Date","key":"grade_submission_date"})
      }
      columns.push({"name":"Course Number","key":"course_number"})
      columns.push({"name":"Course Title","key":"course_title"})
      columns.push({"name":"Course Credits","key":"course_credits"})
      columns.push({"name":"Course Credit Fee","key":"course_credit_fee"})
      columns.push({"name":"Course Fixed Credit Fee","key":"course_fixed_credit_fee"})
      columns.push({"name":"Course Total Credit Fee","key":"course_total_credit_fee"})
      columns.push({"name":"Course Start Date","key":"course_beginning_date"})
      columns.push({"name":"Course End Date","key":"course_ending_date"})
      columns.push({"name":"Email","key":"email"})
      columns.push({"name":"Phone","key":"phone_1"})
      columns.push({"name":"Phone 2","key":"phone_2"})
      columns.push({"name":"Student Previously Enrolled","key":"student_previously_enrolled"})
      columns.push({"name":"Student School District","key":"student_school_district"})
      return columns;

    },
    ...mapState({
      role: 'user.user_role'
    }),
    ordersExist () {
      return !!Object.keys(this.orders).length
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  }
};
</script>


<style lang="scss" scoped>
table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  column-width: 700px;
  border-collapse: separate; /* Cleaner table appearance */
  border-spacing: 0; /* Remove gaps between cells */
  .header-row {
    background-color: white;
  }
  .foot-header-row {
    background-color: white;
    position: sticky;
    bottom: 0; /* Sticks to the bottom */
    z-index: 4; /* Ensure it appears above scrolling content */
  }
  th {
    position: sticky; /* Make the header sticky */
    top: 0; /* Stick to the top of the table */
    z-index: 3; /* Ensure it appears above other elements */
    background-color: white; /* Prevent transparency when scrolling */
    border: 1px solid #000000;
    text-align: left;
    font-size: 12px;
    text-align: center;
  }
}
.view-main table {
  .header-row {
    background-color: $gray-100;
  }
}
td {
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // border: 1px solid #c7c7c7;
  border: .75px solid black;
  font-size: 0.75rem;
  position: relative; /* Ensure sticky positioning works on columns */
}
.btn-select-row {
  background-color: white;
  padding: 0 10px;
  border: 1px solid #c7c7c7;
  &:hover {
    background: rgb(60, 146, 220, 0.15);
    color: black;
  }
}
th {
  margin: 0;
  width: 10rem;
  text-overflow: clip;
  border: 1px solid #c7c7c7;
  font-size: 12px;
  position: relative; /* Ensure sticky positioning works on columns */
}
.table-container {
  overflow: auto;
  max-height: 100vh;
}
th.sticky-column {
  position: sticky; /* Make the column sticky */
  left: 0; /* Stick to the left edge of the container */
  top: 0; /* Stick to the top edge of the container */
  z-index: 6; /* Ensure it stays above scrolling content */
  background-color: inherit; /* Use the row's background color */
}
th.sticky-column-second {
  position: sticky;
  left: 80px; /* Adjust based on the width of the first sticky column */
  top: 0; /* Stick to the top edge of the container */
  z-index: 6;
  background-color: inherit; /* Use the row's background color */
}
td.sticky-column {
  position: sticky; /* Make the column sticky */
  left: 0; /* Stick to the left edge of the container */
  top: 0; /* Stick to the top edge of the container */
  z-index: 4; /* Ensure it stays above scrolling content */
  background-color: inherit; /* Use the row's background color */
}
td.sticky-column-second {
  position: sticky;
  left: 80px; /* Adjust based on the width of the first sticky column */
  top: 0; /* Stick to the top edge of the container */
  z-index: 4;
  background-color: inherit; /* Use the row's background color */
}
</style>